const textsBr = (i18n) => ({
  HeaderContent_details: i18n.gettext(
    'Ingresa a nuestra comunidad global exclusiva, donde tendrás soporte de otros profesionales y acceso a novedades especiales.',
  ),
  EconomicContent_details: i18n.gettext(
    'Los partners que estén en el nivel Silver o superior podrán crear sus perfiles en nuestro Centro de Partners.',
  ),
  headerHero_description: i18n.gettext(
    'Tenga acceso a tarifas diferenciadas, soporte técnico exclusivo y otras ventajas para su agencia y sus clientes. ¡Todo gratis!',
  ),
  Developers_tittle_details: i18n.gettext(
    'Puedes ganar dinero participando de nuestro Programa',
  ),
  steps_3: i18n.gettext(
    'Tu agencia recibe un kit de bienvenida y materiales de onboarding',
  ),
  footer_description: i18n.gettext(
    'Ten acceso a tasas diferenciadas, soporte técnico y muchos otros beneficios exclusivos para tu agencia y tus clientes. Es gratis.',
  ),
  invitations_title: i18n.gettext(
    '¿Ya ofreciste nuestro Checkout? Recibe tu identificación única y conviértete en un partner oficial y homologado.',
  ),
  Levels_first_text_our_levels: i18n.jsx.gettext(
    'Nuestro Partners Program tiene 4 niveles. Según aumenta la facturación de tus clientes que usan Mercado Pago en sus tiendas online, más alto es tu nivel y las ventajas que tienes.',
  ),
  LevelsInfo_OurLevels_certified: i18n.jsx.gettext(
    'Entre {0}$ 45k{1} y {0}$ 149K{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Silver: i18n.jsx.gettext(
    'Entre {0}$ 150K{1} y {0}$ 499K{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Gold: i18n.jsx.gettext(
    'Entre {0}$ 500K{1} y {0}$ 5,9M{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_Platinum: i18n.jsx.gettext('A partir de {0}$ 6M{1}', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
  }),
  LevelsInfo_OurLevels_certified_period: i18n.gettext('Trimestral'),
  LevelsInfo_OurLevels_Silver_period: i18n.gettext('Semestral'),
  LevelsInfo_OurLevels_Gold_period: i18n.gettext('Semestral'),
  LevelsInfo_OurLevels_Platinum_period: i18n.gettext('Semestral'),
  membershipForm_personalTitle: i18n.gettext('Datos de la empresa'),
  membershipForm_companyName: i18n.gettext('Nombre de la empresa'),
  membershipForm_companyName_label: i18n.gettext(
    'Ingresa el nombre de la empresa',
  ),
  membershipForm_siteUrl: i18n.gettext('Ingresa el sitio de la empresa'),
  LevelsInfo_Developers_OurLevels_certified: i18n.jsx.gettext(
    'Entre {0}R$ 0{1} y {0}R$ 2.499/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Silver: i18n.jsx.gettext(
    'Entre {0}R$ 2.500{1} y {0}R$ 199.999/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Gold: i18n.jsx.gettext(
    'Entre {0}R$ 200.000{1} y {0}R$ 490.000/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Platinum: i18n.jsx.gettext(
    'Más de {0}R$ 490.000/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Levels_title: i18n.gettext('Nuestros niveles'),
  Developers_volver_importante: i18n.jsx.gettext(
    '{0} Importante: {1} Todos los desarrolladores empiezan en el nivel Partner Certificado. Por ser una fase de adaptación, reducimos el período de revaluación para 3 meses.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Details_how_it_works: i18n.gettext(
    'Cómo funcionan nuestras bonificaciones financieras',
  ),
  Details_how_it_works_one: i18n.jsx.gettext(
    'Todos los participantes del programa reciben un Integrator ID cuando aprueban la certificación que eligieron. {0}Debes usar esta identificación en todas las integraciones que hagas.{1} De esta manera, podemos validar las operaciones realizadas y contabilizarlas para la bonificación.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Details_how_it_works_one_agencies: i18n.jsx.gettext(''),
  Details_how_it_works_two: i18n.jsx.gettext(
    'Mercado Pago hará el seguimiento del volumen mensual de operaciones y de pagos de cada nueva integración que haces durante los primeros 120 (ciento veinte) días de operación, dividiendo en 2 períodos de conteo de 60 (sesenta) días.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Details_how_it_works_three: i18n.jsx.gettext(
    'Según la facturación mensual promedio de tus clientes en esos dos períodos, recibirás la bonificación correspondiente.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Text_first_levels_agency: i18n.gettext(
    'En la reunión para oficializar nuestra sociedad, recibirás un kit de bienvenida. Ahí, dispondrás de un sello digital, que podrás incluir en tu sitio o publicar en tus redes sociales anunciando la sociedad oficial con Mercado Pago.',
  ),
  LevelsInfo_Developers_OurLevels_certified_period:
    i18n.gettext('Cada 3 meses'),
  LevelsInfo_Developers_OurLevels_Silver_period: i18n.gettext('Cada 6 meses'),
  LevelsInfo_Developers_OurLevels_Gold_period: i18n.gettext('Cada 6 meses'),
  LevelsInfo_Developers_OurLevels_Platinum_period: i18n.gettext('Cada 6 meses'),
  Introduction_title: i18n.gettext(
    'Aumenta tus resultados como desarrollador autónomo participando de nuestro Partners Program',
  ),
  Footnotes_disclaimer_one: i18n.gettext(
    '¹ Válido solo para integraciones con tu identificación única en el programa. Los pagos se hacen en base a la facturación de la integración.',
  ),
  Footnotes_disclaimer_doc: i18n.jsx.gettext('', {}),
  Footnotes_disclaimer_two: i18n.gettext(
    '² Este beneficio aplica solo para desarrolladores con facturación mensual a partir de US$ 4.000.',
  ),
  LevelsInfo_agencies_title: i18n.gettext(
    'Conoce las ventajas del Partners Program',
  ),
  Benefits_agencies_commercial_title: i18n.gettext('Comercial'),
  Footer_agencies_title: i18n.gettext(
    'Conviertete en una agencia partner de Mercado Pago',
  ),
  LevelsInfo_developers_title: i18n.gettext(
    'Conoce las ventajas del Partners Program para desarrolladores certificados',
  ),
});

export default textsBr;
